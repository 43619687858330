import styled, { css } from 'styled-components';
import { isEmpty } from 'theme/utils/functions';
import breakpointsMedia from 'theme/utils/breakpointsMedia';
import { TextStyleVariants } from 'components/foundation/Text';

const upArrow =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADJSURBVHgB7ZK9DcIwEIXPMSEpM0LYICPAJpRJlxHCBjT8dIgJGIFR8AhpIhwkdNhxLCEh5AOn9CdZPt29e6+wAQIBXxhVmB7K/InsqmvOcCWro6DsRRRRdqqz0TzXZ6hVj7LrDNDm3f1hzS15rHuEEGdAJ/sLABafEyziYeYREO/KNSAsR8Pzm7mp1SzZV/XfARiB0DdD3HAGje1zljaoeiqgRW403/j1F91MQLKQ1VZQ9ki/yIcQ4GRGFUqZtvOkF0NNfOBAYBpenlVFlGYLMXcAAAAASUVORK5CYII=';

const downArrow =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADjSURBVHgB7ZRBDoIwEEWnowksOUJJFNh6A7mBnkBvIkfwBsaTwA10CQkGjuAWEjoOxLhRaY3RVd8CSpk/bTp/CmCxfIv4JPgShhUQAbbtwq/rq4lmCoZUUSQ7peTw4boeP40WQPgxdgEtoy4qg2DFAQce7hHxyEWu+vkJoq+U2hBAQojxPM+zdzlGXSSEkETUOyZ5OIjh8Y5f2yGm6+RoDtBQhmHK3l++VotsVhTxmF5bg0nTrHkX56fcPNf/0+mNOrmS0lOOc+LjkndRbdrNxldF38mKKB2uCi6sn+c1WCx/4Qb4Qk56nWrdvgAAAABJRU5ErkJggg==';

const eighteenMoreIcon =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAASCAYAAAC0EpUuAAAACXBIWXMAABCcAAAQnAEmzTo0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAH+SURBVHgBjVSNWcIwEL1WB2ABtSOUCQwTAC4ATgBMAEwgTIBOUDoBYQLZwAgLuACN7zUphMqP931p83P37t3lLpGckURljUKkH4m0LZbiBmWDobe6O5Ircl/feFBZB4BvADQYOYbG9g9Gw4M36jZPKltB79XoruE6Cg8fVT6OpBiC3RRsZnJDGBGdgMQqBijmhsD3R4V8YKVgyM1v7/GWAGyCX5vmmC8YHeatyHtMvLcpPL37PbUHi53uLv06hU4HUZid1zlGmH3BtlWFH3uPY2RCV4BQWtAJPGaB009eHAzGzOEpZ5uLy7scQCEqFvsRbOYYoyDMIS8MaWnyQsBW0VF1vtUvQxA6gvrDBJu62sR8uQ88X5CL52SaiEuwXFFiJaQMuyhTEs9CZiTGUgxBbwpYKzJD6HP8kaai48vpcA6gQQhq6CxUOsO0Z91FLj3rRI5dxmJPcG4O+iyDyOUnlcsClrYC+eMc9s+46HVAAgWBsGxZVuclcufK55SltgEZvgNlPq1Ljz4BrS6CBV8d3EHJutZjNWjoNLFes0EA2qr06ATfqQm68ND7AByyCWhs/tmm/q0oWzushtqDkk3wwxsgo3orhuJDXnAaBe15FtQb9Jlf65bz2L2hpRFKJ+WlYNqHKSrBjkKGF0Fr4D1MU/wbrBDrqoQtvAw7sC6/LDfhey6G0WwAAAAASUVORK5CYII=';

const oddTrendIndicator = {
  up: () => css`
    background-image: url(${upArrow});
  `,
  down: () => css`
    background-image: url(${downArrow});
  `,
};

const oddModifiers = {
  singleLine: theme => css`
    ${breakpointsMedia({
      lg: css`
        .odd__container {
          flex: 1;
          order: 2;

          .odds__odd {
            align-content: center;
          }
        }

        .odd__bet-button {
          width: 14.5rem;
          flex-direction: column;
          align-items: flex-start;
          gap: ${theme.spacings.xs};

          a {
            height: 2.4rem;
          }

          svg {
            width: 5.8rem;
            height: 2rem;
          }
        }
      `,
    })}
  `,
  onlyOdds: theme => css`
    padding: 0;
    background: transparent;
    border: 0.5px solid transparent;

    .odds__odd {
      border: 0.5px solid ${theme.colors.base['00']};
    }
  `,
  variant: () => css`
    .odd__container {
      span {
        &.odd__title {
          ${TextStyleVariants({
            $variant: { typography: 'bodyTextXXXSmall' },
          })};
          margin-bottom: 0.2rem;
        }
      }
    }
  `,
};

export const Odds = styled.div`
  ${({ theme, singleLine, onlyOdds, variant }) => css`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    border-radius: 0.6rem;
    gap: ${theme.spacings.xs};
    padding: ${theme.spacings.sm};
    border: 0.5px solid ${theme.colors.base['200']};
    background: linear-gradient(
      90deg,
      rgba(134, 163, 246, 0.24) 0%,
      rgba(134, 163, 246, 0) 50.5%,
      rgba(134, 163, 246, 0.24) 100%
    );

    ${breakpointsMedia({
      lg: css`
        margin-bottom: ${theme.spacings.smd};
      `,
    })}

    .odd__container {
      display: flex;
      width: 100%;
      gap: ${theme.spacings.xs};

      > a {
        width: calc(100% / 3 - 0.3rem);
      }
    }

    .odd__bet-button {
      gap: ${theme.spacings.xs};
    }

    ${!!singleLine && oddModifiers.singleLine(theme)}
    ${!!onlyOdds && oddModifiers.onlyOdds(theme)}
    ${!!variant && oddModifiers.variant()}
  `}
`;

export const Odd = styled.div`
  ${({ theme, trendIndicator }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: 0.2rem;
    border-radius: 0.5rem;
    justify-content: center;
    /* width: calc(100% / 3 - 0.3rem); */
    width: 100%;
    background: ${theme.colors.base['00']};
    border: 0.5px solid ${theme.colors.base['200']};

    span {
      &.odd__title {
        width: 100%;
        display: block;
        text-align: center;
        ${TextStyleVariants({
          $variant: { typography: 'bodyTextXXSmall' },
        })};
      }

      &.odd__value {
        display: flex;
        align-items: center;
        color: ${theme.colors.logo.betnacional};

        ${isEmpty(trendIndicator)
          ? ''
          : css`
              &::before {
                content: '';
                width: 1.6rem;
                height: 1.6rem;
                display: inline-block;
                background-size: contain;
                ${oddTrendIndicator[trendIndicator] || oddTrendIndicator.up()};
              }
            `}
      }
    }
  `}
`;

export const BetButton = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${theme.spacings.xs};

    a {
      display: flex;
      height: 1.8rem;
      padding: 0 0.6rem;
      align-items: center;
      border-radius: 0.4rem;
      justify-content: center;
      gap: ${theme.spacings.xs};
      background: ${theme.colors.logo.betnacional};

      span {
        text-transform: uppercase;
      }
    }

    p {
      gap: 0.2rem;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: ${theme.colors.logo.betnacional};

      &::after {
        content: '';
        width: 1.35rem;
        height: 1.2rem;
        display: inline-block;
        background-size: contain;
        background-image: url(${eighteenMoreIcon});
        background-repeat: no-repeat;
      }
    }

    svg {
      width: 50px;
      height: 10px;
    }
  `}
`;
