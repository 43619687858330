import React, { useRef, useState, useEffect } from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Text from 'components/foundation/Text';

import * as S from './styles';

const shieldPlaceholder = '/images/logos/shield-team-placeholder.svg';

const Team = ({ team, className, size = 'small' }) => {
  const bigLogo = team?.logo.replace('medium', 'big');
  const errorLogo = useRef(false);
  const [teamLogo, setTeamLogo] = useState(bigLogo || shieldPlaceholder);

  useEffect(() => {
    if (teamLogo !== bigLogo && !errorLogo.current) {
      setTeamLogo(bigLogo);
    }
  }, [bigLogo, team, teamLogo]);

  const imageSize = size === 'large' ? 64 : 40;

  return (
    <S.Team className={className}>
      <Image
        src={teamLogo}
        alt={team?.name}
        width={imageSize}
        height={imageSize}
        onLoad={el => {
          if (el.target.naturalWidth <= 5) {
            errorLogo.current = true;
            setTeamLogo(shieldPlaceholder);
          }
        }}
        onError={() => {
          errorLogo.current = true;
          setTeamLogo(shieldPlaceholder);
        }}
      />

      <Text
        as='span'
        $csscolor='900'
        className='team__name'
        $variant={{ typography: 'heading5' }}
        onTouchStart={e => {
          e.stopPropagation();
          e?.nativeEvent?.stopImmediatePropagation();
          e.target.classList.add('team__name--show');
        }}
        onTouchEnd={e => {
          e.stopPropagation();
          e?.nativeEvent?.stopImmediatePropagation();
          e.target.classList.remove('team__name--show');
        }}
      >
        {team?.name}
      </Text>
    </S.Team>
  );
};

Team.propTypes = {
  className: PropTypes.string,
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }).isRequired,
  size: PropTypes.oneOf(['small', 'large']),
};

export default React.memo(Team);
